import { ref, watch, nextTick } from 'vue'
import * as ultrabot from '@/ultrabot-logic'

const MESSAGE_TYPE_QUERY = 0
const MESSAGE_TYPE_REPLY = 1
const VISUAL_STATUS_ACTIVE = 2
const VISUAL_STATUS_INACTIVE = 3

const visualStatus = ref({})
const openedBefore = ref(false)
const queryInput = ref("")

const init = () => {
    visualStatus.value = {
        chatbot: undefined,
        chatMessages: undefined,
        chatForm: undefined,
        poweredBy: undefined,
        chatbotOpenButton: undefined,
        chatbotCloseButton: undefined
    }
    
    openedBefore.value = false
    queryInput.value = ""

    ultrabot.init()
}

const openChat = () => {
    visualStatus.value.chatbot = VISUAL_STATUS_ACTIVE
    visualStatus.value.chatbotOpenButton = VISUAL_STATUS_INACTIVE

    if (!openedBefore.value) {
        openedBefore.value = true
    }

    setTimeout(() => {
        visualStatus.value.chatMessages = VISUAL_STATUS_ACTIVE
        visualStatus.value.chatForm = VISUAL_STATUS_ACTIVE
        visualStatus.value.chatbotCloseButton = VISUAL_STATUS_ACTIVE

    }, 600)
    setTimeout(() => {
        if (visualStatus.value.chatbot == VISUAL_STATUS_ACTIVE) {
            visualStatus.value.poweredBy = VISUAL_STATUS_ACTIVE
        }
    }, 1400)
}

const closeChat = () => {
    setTimeout(() => {
        visualStatus.value.chatbotCloseButton = VISUAL_STATUS_INACTIVE
        visualStatus.value.chatMessages = VISUAL_STATUS_INACTIVE
        visualStatus.value.chatForm = VISUAL_STATUS_INACTIVE
    }, 0)
    setTimeout(() => {
        visualStatus.value.chatbot = VISUAL_STATUS_INACTIVE
        visualStatus.value.poweredBy = VISUAL_STATUS_INACTIVE
    }, 150)
    setTimeout(() => {
        visualStatus.value.chatbotOpenButton = VISUAL_STATUS_ACTIVE
    }, 600)
}

function submitQueryForm() {
    if(ultrabot.processingReply.value || !queryInput.value) {
        return
    }

    console.debug("BEFORE SENDMESSAGE!")
    ultrabot.sendMessage(queryInput.value)
    console.debug("AFTER SENDMESSAGE!")
    queryInput.value = ''
}

export {
    visualStatus,
    openedBefore,
    queryInput,
    init,
    openChat,
    closeChat,
    submitQueryForm,
    VISUAL_STATUS_ACTIVE,
    VISUAL_STATUS_INACTIVE
}