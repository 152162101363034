<script setup>
import { visualStatus, queryInput, submitQueryForm, VISUAL_STATUS_ACTIVE, VISUAL_STATUS_INACTIVE } from './chatbot'
import { messagesList, processingReply, MESSAGE_TYPE_QUERY, MESSAGE_TYPE_REPLY } from '@/ultrabot-logic'
import { onMounted, ref } from 'vue';

const textField = ref()
onMounted(() => {
    textField.value.$el.querySelector('input').setAttribute('class', 'chatbot-input')
})

</script>

<template>
    <v-list class="chat-messages" :class="(() => {
        switch (visualStatus.chatMessages) {
            case VISUAL_STATUS_ACTIVE:
                return 'appearance-animation'
            case VISUAL_STATUS_INACTIVE:
                return 'disappearance-animation'
            default:
                return ''
        }
    })()">
        <v-list-item v-for="( message, index ) in  messagesList " :key="index">
            <div class="message"
                :class="{ 'query': message.type == MESSAGE_TYPE_QUERY, 'reply': message.type == MESSAGE_TYPE_REPLY }">
                {{ message.content }}
            </div>
        </v-list-item>
    </v-list>

    <v-form @submit.prevent="submitQueryForm">
        <div class="chat-form" :class="(() => {
            switch (visualStatus.chatForm) {
                case VISUAL_STATUS_ACTIVE:
                    return 'appearance-animation'
                case VISUAL_STATUS_INACTIVE:
                    return 'disappearance-animation'
                default:
                    return ''
            }
        })()">
            <v-text-field v-model:modelValue="queryInput" class="send-message-field" variant="solo" rounded="xl"
                density="compact" placeholder="Type here" ref="textField">
            </v-text-field>
            <v-btn :loading="processingReply ? 'secondary' : undefined"
                 variant="flat" class="send-message-btn"
                type="submit">
                <v-icon>
                    <svg style="fill: rgb(var(--v-theme-secondary));" xmlns="http://www.w3.org/2000/svg" width="15"
                        height="13" viewBox="0 0 15 13" fill="none">
                        <path
                            d="M14.3455 0.112917C14.2841 0.0576529 14.2094 0.020909 14.1294 0.00665036C14.0494 -0.0076083 13.9673 0.0011596 13.8917 0.0320079L0.9947 5.29176C0.837832 5.35572 0.705112 5.471 0.616471 5.62027C0.527831 5.76955 0.488056 5.94476 0.503119 6.1196C0.518182 6.29444 0.58727 6.45947 0.700009 6.5899C0.812747 6.72034 0.963048 6.80913 1.12834 6.84296L4.57247 7.54793V11.5397C4.57254 11.7247 4.62568 11.9055 4.72506 12.0589C4.82444 12.2123 4.96553 12.3312 5.13022 12.4004C5.29491 12.4696 5.47569 12.4859 5.64936 12.4473C5.82303 12.4087 5.98166 12.3169 6.10489 12.1836L7.80023 10.3512L10.4463 12.7685C10.6079 12.9175 10.8161 12.9998 11.0318 13C11.126 12.9998 11.2196 12.9843 11.3093 12.9542C11.4563 12.9058 11.5885 12.818 11.6928 12.6996C11.7971 12.5813 11.8698 12.4365 11.9037 12.2798L14.4887 0.568528C14.5068 0.486195 14.5031 0.400256 14.4779 0.319969C14.4526 0.239683 14.4069 0.168094 14.3455 0.112917ZM1.26644 6.05973C1.264 6.05288 1.264 6.04535 1.26644 6.03851C1.26931 6.0362 1.27254 6.0344 1.27598 6.0332L11.4397 1.88695L4.86585 6.79454L1.27598 6.06238L1.26644 6.05973ZM5.55505 11.6305C5.53751 11.6495 5.51495 11.6626 5.49024 11.6681C5.46553 11.6737 5.4398 11.6714 5.41632 11.6617C5.39283 11.652 5.37267 11.6351 5.35839 11.6134C5.34411 11.5917 5.33636 11.566 5.33614 11.5397V8.09837L7.22557 9.82266L5.55505 11.6305ZM11.1597 12.1001C11.155 12.1225 11.1446 12.1432 11.1296 12.16C11.1146 12.1768 11.0956 12.1891 11.0744 12.1956C11.0529 12.2034 11.0297 12.2051 11.0073 12.2005C10.9849 12.1958 10.9641 12.1849 10.9472 12.169L5.56778 7.25148L13.5455 1.29339L11.1597 12.1001Z" />
                    </svg>
                </v-icon>
            </v-btn>
        </div>
    </v-form>
</template>

<style scoped>
.chat-messages {
    background: transparent;
    max-height: 16rem;
    position: absolute;
    left: 3px;
    right: 3px;
    bottom: 95px;
    padding-bottom: 0px;
}

.chat-messages::-webkit-scrollbar {
    background: transparent;
    width: 3px !important;
}

.chat-messages::-webkit-scrollbar-thumb {
    background: rgb(var(--v-theme-primary));
    border-radius: 10px;
}

.message {
    backdrop-filter: blur(2.5px);
    padding: 6px;
    align-items: center;
    max-width: 14rem;
}

.v-list-item:not(:last-child) {
    margin-bottom: 3px;
}

.query {
    border-radius: 10px 10px 0px 10px;
    background: rgb(var(--v-theme-secondary));
    color: rgb(var(--v-theme-primary));
    float: right;
    animation-name: slide-left;
    animation-duration: 0.6s;
    animation-timing-function: ease;
}

.reply {
    border-radius: 10px 10px 10px 0px;
    background: rgb(var(--v-theme-primary));
    color: rgb(var(--v-theme-secondary));
    float: left;
    animation-name: slide-right;
    animation-duration: 0.6s;
    animation-timing-function: ease;
}


@keyframes slide-left {
    from {
        position: relative;
        left: 14rem
    }

    to {
        position: relative;
        left: 0rem
    }
}

@keyframes slide-right {
    from {
        position: relative;
        right: 14rem
    }

    to {
        position: relative;
        right: 0rem
    }
}

.chat-form {
    display: flex;
    position: absolute;
    bottom: 50px;
    left: 10px;
    right: 10px;
}

.send-message-field {
    margin-right: 15px;
}

.send-message-field>>>.v-input__details {
    display: none;
}

.send-message-field>>>.v-field--variant-solo {
    box-shadow: none;
    border: 1px solid rgba(5, 112, 255, 0.13);
}

.send-message-field>>>.v-field__input {
    min-height: 5px !important;
    padding-block: 3px;
}

.send-message-btn {
    display: flex;
    width: 37px;
    height: 37px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 75px;
    background: rgb(var(--v-theme-primary));
}

</style>

<style>
.chatbot-input {
    padding: 0px !important;
    border: 0px !important;
}
</style>