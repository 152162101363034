import * as api from './useAPI'
import {ref, watch} from 'vue'

const MESSAGE_TYPE_QUERY = 0
const MESSAGE_TYPE_REPLY = 1

const messagesList = ref([])
const processingReply = ref(false)

function init() {
    api.init()
}

function addNewMessage(content, type) {
    const length = messagesList.value.push({content, type})
    return length - 1
}

async function sendMessage(messageContent) {
    if(processingReply.value) {
        console.debug("processingReply.value ... returning")
        return
    }

    addNewMessage(messageContent, MESSAGE_TYPE_QUERY)

    processingReply.value = true
    console.debug("BEFORE AWAIT")
    const { answer, generationFinished } = await api.sendMessage(messageContent)
    console.debug("AFTER AWAIT")
    const answerIndex = addNewMessage('', MESSAGE_TYPE_REPLY)

    const unwatchAnswer = watch(answer, (updatedAnswer) => {
       messagesList.value[answerIndex].content = updatedAnswer
    })

    const unwatchFlag = watch(generationFinished, (_) => {
        processingReply.value = false
        unwatchAnswer()
        unwatchFlag()
    })
}

export {
    MESSAGE_TYPE_QUERY,
    MESSAGE_TYPE_REPLY,
    messagesList,
    processingReply,
    init,
    sendMessage
}