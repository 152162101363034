import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'


loadFonts()
const mountElement = document.createElement('div')
mountElement.setAttribute('id', 'chatbot-client-app')
document.body.append(mountElement)

createApp(App)
  .use(vuetify)
  .mount('#chatbot-client-app')
