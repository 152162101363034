import { watch, ref } from 'vue'
import { io } from 'socket.io-client'

const API_DOMAIN = 'api.ultrabot.dev'
const CHAT_DOMAIN = 'chat.ultrabot.dev'
//const API_DOMAIN = // 'localhost:4000'
//const API_DOMAIN2 = // 'localhost:5000'
const API_VERSION = 'v1'
const API_BASE_URL = `https://${API_DOMAIN}/${API_VERSION}/conversations`
const OUTPUT_STREAMING_URL = `https://${CHAT_DOMAIN}/${API_VERSION}/output-streaming`
const SEND_MESSAGE_ENDPOINT = 'messages' // ATTENTION: must be prefixed by <conversation_key>/

const INIT_CONVERSATION_SUCCESS = 201
const SEND_MESSAGE_SUCCESS = 201

const ERROR_MESSAGE = 'Apologies, an error has occurred. Please try again.'

const SOCKETIO_MESSAGE_EVENT = 'message'
const SOCKETIO_TOEKN_EVENT = 'token'
const SOCKETIO_FINISHED_EVENT = 'finished'
const SOCKETIO_ERROR_EVENT = 'error'

const socket = io(OUTPUT_STREAMING_URL, { reconnection: false })

socket.on('connect', () => {
    const engine = socket.io.engine;
    engine.on("close", (reason) => {
        console.log("engine close reason=["+reason+"]")
    });
    engine.once("upgrade", () => {
        // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
        console.log("engine upgrade. transport name=["+engine.transport.name+"]"); // in most cases, prints "websocket"
    });
    engine.on("drain", () => {
        console.log("engine drain")
    });
    console.log(`new session id: ${socket.id}`)
})

socket.on('disconnect', (reason) => {
    console.log(`session disconnect: [${reason}]`)
})

socket.on('message', (msg) => {
    console.log(`received message: ${msg} on session id ${socket.id}`)
})

socket.on('connect_error', (err) => {
    console.log(`A socket.io connection error occurred: ${err}`)
})

socket.on('reconnect', (attemptNumber) => {
    console.log('Reconnected with ID:', socket.id, 'after', attemptNumber, 'attempts');
});


socket.io.on("close", () => {
    console.log('Closing. session id is :', socket.id);
});

socket.onAny((event, ...args) => {
    console.log(`SOCKET IO EVENT ${event}`);
});

const keys = ref({
    bot_api_key: undefined,
    conversation_key: undefined
})

function getAPIKey() {
    let bot_key = null;
    let scriptElement = document.getElementById('ultrabot-loader');
    if (scriptElement === null) {
        throw new Error("Cannot find Ultrabot <script> tag. Did you set 'id=\"ultrabot-loader\"' on it?");
    }
    let src_url = new URL(scriptElement.getAttribute('src'));
    if (src_url.searchParams.has('bot_key')) {
        bot_key = src_url.searchParams.get('bot_key');
    }
    else {
        // Deprecated. Please include the key as a query string param with key bot_key instead.
        bot_key = scriptElement.getAttribute('api-key');
    }
    if (bot_key === null) {
        throw new Error("Cannot find Ultrabot bot key. Did you include '?bot_key=<your_bot_key>' in the <script> tag's 'src' attribute?");
    }
    else {
        console.debug("getAPIKey() found bot key: [" + bot_key + "]");
        return bot_key;
    }
}

async function init(api_key = undefined) {
    keys.value.bot_api_key = (api_key) ? api_key : getAPIKey()
    keys.value.conversation_key = await initConversation(keys.value.bot_api_key)
}

async function postJSON(url, bot_api_key, headers, body) {
    const sendReq = async () => {
        return fetch(
            url,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${bot_api_key}`,
                    'Content-Type': 'application/json',
                    ...headers
                },
                body: JSON.stringify(body)
            })
            .catch(error => {
                console.error(error)
            })
    }

    let threshold = 3
    let res = await sendReq()
    while ((!res || !res.ok) && threshold--) {
        res = await sendReq()
    }

    return res
}

async function initConversation(bot_api_key) {
    const res = await postJSON(
        `${API_BASE_URL}`,
        bot_api_key,
        {},
        {}
    )

    if (res && res.status === INIT_CONVERSATION_SUCCESS) {
        const data = await res.json()
        return data.conversation_key
    } else {
        return undefined
    }
}

async function sendMessage(message) {
    console.debug("Starting sendMessage() message=["+message+"]")
    // Block until conversation key is ready
    if (!keys.value.conversation_key) {
        await new Promise((resolve) => {
            const unwatcher = watch(() => keys.value.conversation_key, async (_) => {
                console.debug("Calling unwatcher()")
                unwatcher()
                console.debug("Calling resolve()")
                resolve()
            })
        })
    }
    console.debug("After unwatcher-solve block!")
    return sendMessageImpl(message)
}

function sendMessageImpl(message) {
    console.debug("Starting sendMessageImpl() message=["+message+"]")
    const answer = ref('')
    const generationFinished = ref(false)

    const onTokenHandler = (token) => {
        console.debug("onTokenHandler handling token: ["+token+"]")
        answer.value = `${answer.value}${token}`
    }
    const onFinishedHandler = () => {
        console.debug("onFinishedHandler removing custom handlers")
        generationFinished.value = true
        removeCustomHandlers()
    }
    const onErrorHandler = (err) => {
        console.debug("onErrorHandler!")
        console.log("ERROR!", err)
        if (answer.value.length > 0) {
            answer.value += '\n\n'
        }
        answer.value += ERROR_MESSAGE
        generationFinished.value = true
        removeCustomHandlers()
    }
    const removeCustomHandlers = () => {
        console.debug("removeCustomHandlers is removing")
        socket.removeListener(SOCKETIO_TOEKN_EVENT, onTokenHandler)
        socket.removeListener(SOCKETIO_FINISHED_EVENT, onFinishedHandler)
        socket.removeListener(SOCKETIO_ERROR_EVENT, onErrorHandler)
    }

    socket.emit(SOCKETIO_MESSAGE_EVENT, 
        { bot_key: keys.value.bot_api_key, conversation_key: keys.value.conversation_key, message: message })
    
    socket.on(SOCKETIO_TOEKN_EVENT, onTokenHandler)
    socket.on(SOCKETIO_FINISHED_EVENT, onFinishedHandler)
    socket.on(SOCKETIO_ERROR_EVENT, onErrorHandler)

    console.debug("Returning answer: ["+answer+"] and generationFinished ["+generationFinished+"]")
    return { answer: answer, generationFinished: generationFinished }
}

export {
    init,
    sendMessage,
    getAPIKey
}
