import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'

const mlaiaTheme = {
  dark: false,
  colors: {
    primary: '#0570FF',
    secondary: '#FAFAFA'
  }
}

export default createVuetify({
  theme: {
    defaultTheme: 'mlaiaTheme',
    themes: {
      mlaiaTheme
    }
  },
  customProperties: true
})
